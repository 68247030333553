<template>
    <div class="col-lg-6 col-xs-12 col-md-8">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :cols-to-delete="{start_col: 0, ncols: 1}"
                        :has-add-function="$can('create','User')"
                        file-name="users"
                        table-id="userTable"
                        @add-action="newUser"
                    ></ExportTable>
                    <v-btn class="ml-3 warning" @click="openResetAllPasswordsDialog()" v-if="$can('update','AllUserPasswords')">{{ $t('message.resetAllPasswords') }}</v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.users"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [15,30,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="allUsers"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            id="usersTable"
            item-key="User.id"
        >
            <template v-slot:item.User.photo="{ item }">
                <img :src="'data:image/jpeg;base64,'+ item.User.photo" alt="avatar" height="35" width="35" class="img-responsive rounded-circle" v-if="item.User.photo != null">
            </template>
            <template v-slot:item.User.title="{ item }">
                <span class="font-weight-bold">{{ item.User.title }}</span>
            </template>
            <template v-slot:item.User.role="{ item }">
                {{ getRole(item.User.role) }}
            </template>
            <template v-slot:item.User.userstatus_id="{ item }">
                <span class="green--text darken-1" v-if="item.User.userstatus_id == 1">{{ $t('message.activated') }}</span>
                <span class="red--text darken-1" v-else>{{ $t('message.suspended') }}</span>
            </template>
            <template v-slot:item.User.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="updateUser(item.User.id)" v-if="$can('update','User')">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateUser') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="openUserResetPasswordDialog(item.User.email, item.User.title)" v-if="$can('update','UserPassword') && item.User.userstatus_id == 1">
                                <v-icon color="black lighten-1" small class="mr-1">mdi-lock-reset</v-icon>{{ $t('message.resetPassword') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="logOutUser(item.User.email, item.User.title)" v-if="$can('update','UserPassword') && item.User.userstatus_id == 1">
                                <v-icon color="red lighten-1" small class="mr-1">power_settings_new</v-icon>{{ $t('message.logOut') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="activateUser(item.User.email, item.User.title)" v-if="item.User.userstatus_id != 1 && $can('update','User')">
                                <v-icon small class="mr-1" color="green darken-1">mdi-play</v-icon>{{ $t('message.activateUser') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="suspendUser(item.User.email, item.User.title)" v-if="item.User.userstatus_id == 1 && $can('update','User')">
                                <v-icon small class="mr-1" color="red darken-1">pause</v-icon>{{ $t('message.suspendUser') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteUser(item.User.email, item.User.title)" v-if="$can('delete','User')">
                                <v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteUser') }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <UserPasswordReset
            :user-id="selectedUserId"
            :user-name="selectedUserName"
            :dialog.sync="userResetPasswordDialog"
            @dialog-closed="resetPasswordDialogClosed()"
            @reset-done="userPasswordReset()"
        ></UserPasswordReset>
        <AllPasswordsReset
            :users="allUsers"
            :dialog.sync="resetAllPasswordsDialog"
            @dialog-closed="resetAllPasswordsDialogClosed()"
            @reset-done="allPasswordsReset()"
        ></AllPasswordsReset>
    </div>
</template>

<script>
import {formatDate} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from "vuex";
import ExportTable from "Components/Appic/ExportTable";
const AllPasswordsReset = () => import("Components/Appic/AllPasswordsReset");
const UserPasswordReset = () => import("Components/Appic/UserPasswordReset");

export default {
    name: "UsersListing",
    components: {AllPasswordsReset, ExportTable, UserPasswordReset },
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            filterOptions: {},
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                users: false
            },
            resetAllPasswordsDialog: false,
            searchField: null,
            searchTerm: null,
            selectedUserId: null,
            selectedUserName: null,
            tableOptions: {
                page: 1
            },
            totalUsers: 0,
            updateMode: false,
            userResetPasswordDialog: false
        }
    },
    computed: {
        ...mapFields('user',{
            allUsers: 'allUsers'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('user',{
            User: 'User'
        }),
        ...mapGetters([
            'userRoles'
        ]),
        allowAction: function () {
            return this.User.email == 'vanjoe@apptimber.com' // temporary
        },
        formatDate: () => formatDate,
        headers() {
            return [
                {
                    id: 0,
                    text: this.$t('message.actions'),
                    value: 'User.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 1,
                    text: "",
                    value: 'User.photo',
                    class: 'light-green lighten-3 pa-1 width-5-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.user'),
                    value: 'User.title',
                    class: 'light-green lighten-3 pa-1 width-25-pct',
                    searchable: true,
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.abbr'),
                    value: 'User.abbreviation',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    searchable: true,
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.login'),
                    value: 'User.email',
                    class: 'light-green lighten-3 pa-1',
                    searchable: true,
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.userType'),
                    value: 'User.role',
                    class: 'light-green lighten-3 pa-1',
                    searchable: true,
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.status'),
                    value: 'User.userstatus_id',
                    class: 'light-green lighten-3 pa-1',
                    searchable: true,
                    sortable: true
                }
            ]
        },
        searchFields() {
            return this.headers.filter((header) => header.searchable === true)
        }
    },
    methods: {
        ...mapActions('user',{
            activateUserByEmailId: 'activateUserByEmailId',
            deleteUserByEmailId: 'deleteUserByEmailId',
            getAllUsers: 'getAllUsers',
            logOutUserByEmailId: 'logOutUserByEmailId',
            resetAllUsers: 'resetAllUsers',
            suspendUserByEmailId: 'suspendUserByEmailId'
        }),
        allPasswordsReset() {
            this.resetPasswordDialog = false;
        },
        dialogClosed() {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        getRole(val) {
            return this.userRoles.find(f => f.value == val)?.text
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        newUser() {
            let tab = window.open('/v1/users/add','_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        openResetAllPasswordsDialog() {
            this.resetAllPasswordsDialog = true
        },
        openUserResetPasswordDialog(userId, userName) {
            this.selectedUserId = userId
            this.selectedUserName = userName
            this.userResetPasswordDialog = true
        },
        resetAllPasswordsDialogClosed() {
            this.resetAllPasswordsDialog = false;
        },
        resetPasswordDialogClosed() {
            this.userResetPasswordDialog = false;
        },
        resetSearch() {
            this.searchField = 'User.title'; //default search field
            this.searchTerm = null;
            this.filterOptions = {};
        },
        async activateUser(val, title) {
            if(await this.$root.$confirm(this.$t('message.activateUser') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueUserActivateAction'), {color: 'orange'})) {
                this.activateUserByEmailId(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.userActivated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetAllUsers()
                            this.getAllUsers()
                        } else {
                            this.$toast.error(this.$t('message.errors.userNotActivated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.userNotActivated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        async deleteUser(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteUser') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueUserDeleteAction'), {color: 'orange'})){
                this.deleteUserByEmailId(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.userDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetAllUsers()
                            this.getAllUsers()
                        } else {
                            this.$toast.error(this.$t('message.errors.userNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.userNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        async logOutUser(val, title) {
            if(await this.$root.$confirm(this.$t('message.logOutUser') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueUserLogOutAction'), {color: 'orange'})) {
                this.logOutUserByEmailId(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.userLoggedOut'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        } else {
                            this.$toast.error(this.$t('message.errors.userNotLoggedOut'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.userNotSuspended'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        async suspendUser(val, title) {
            if(await this.$root.$confirm(this.$t('message.suspendUser') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueUserSuspendAction'), {color: 'orange'})) {
                this.suspendUserByEmailId(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.userSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetAllUsers()
                            this.getAllUsers()
                        } else {
                            this.$toast.error(this.$t('message.errors.userNotSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.userNotSuspended'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        updateUser(val) {
            let tab = window.open('/v1/users/update/' + val, '_blank',)
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        userPasswordReset () {
            this.resetPasswordDialog = false;
        },
    },
    created() {
        //temporary
        this.resetAllUsers()
        if(this.allUsers.length == 0) this.getAllUsers()
    }
}
</script>

<style>
    .text-start {
        padding-left: 3px !important;
        padding-top: 3px !important;
        padding-right: 3px !important;
        vertical-align: middle !important;
    }
    .truncate {
        display: inline-block;
        width: 100px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table td {
        padding: 0 3px;
        font-size: 0.8rem !important;
        line-height: 0.8rem;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
</style>